<template>
  <v-app>
    <!-- <v-layout row wrap align-center justify-center class="opansans-font ma-0" v-if="!isParamAvil">
      
      <v-flex xs12 sm12 md6 lg6 xl6 id="imgPart" d-flex align-center flex-column justify-center
        v-if="!$store.state.isMobileView">
        <img src="../assets/DashboardTop.svg" alt="alignLoginleftImg" class="alignLoginleftImg" />
        <img src="../assets/DashboardBottom.svg" alt="alignLoginleftImg" />
        <div class="w-100 d-flex fsize12 justify-center mt-8">
          Alice Blue Financial Services (P) Ltd
        </div>
        <div class="w-100 d-flex fsize12 justify-center">
          NSE EQ,FO,CDS-MEMBER ID:90112, SEBI Reg No:INZ000156038 BSE
          EQ,BFO,BCD-MEMBER ID:6670, SEBI Reg No:INZ000156038
        </div>
        <div class="w-100 d-flex fsize12 justify-center">
          CDSL DP ID 12085300, DP SEBI Reg No:IN-DP-364-2018 MCX MEMBER ID:
          56710, SEBI Reg No:INZ000156038
        </div>
        <div class="w-100 d-flex fsize12 justify-center">Version 2.0.0.1</div>
      </v-flex>
      
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center>
        <form @keyup.enter="login()">
          <v-row class="ma-0 justify-center">
            <v-card class="pa-6 d-flex flex-column justify-space-between cardBoxShadow rounded-lg" min-width="350" min-height="250">
              <div class="fsize22 mb-6 primaryColor text-center">
                {{getBrokerName}}
              </div>
              <v-btn max-height=40 @click="login()" block depressed class="text-capitalize mt-3 primary">Login With SSO</v-btn>
            </v-card>
          </v-row>
        </form>
      </v-flex>
    </v-layout> -->

    <!-- <v-layout row wrap align-center justify-center class="opansans-font ma-0"
      style="height: calc(100vh - 56px) !important; margin-top: 56px !important">
      <v-flex xs12 sm12 md6 lg6 xl6 id="imgPart" d-flex align-center flex-column justify-center
        v-if="!$store.state.isMobileView">
        <img src="../assets/DashboardTop.svg" alt="alignLoginleftImg" class="alignLoginleftImg" />
        <img src="../assets/DashboardBottom.svg" alt="alignLoginleftImg" />
        <div class="w-100 d-flex fsize12 justify-center mt-8">
          Alice Blue Financial Services (P) Ltd
        </div>
        <div class="w-100 d-flex fsize12 justify-center">
          NSE EQ,FO,CDS-MEMBER ID:90112, SEBI Reg No:INZ000156038 BSE
          EQ,BFO,BCD-MEMBER ID:6670, SEBI Reg No:INZ000156038
        </div>
        <div class="w-100 d-flex fsize12 justify-center">
          CDSL DP ID 12085300, DP SEBI Reg No:IN-DP-364-2018 MCX MEMBER ID:
          56710, SEBI Reg No:INZ000156038
        </div>
        <div class="w-100 d-flex fsize12 justify-center">Version 2.0.0.1</div>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center></v-flex>
    </v-layout> -->
    <v-layout row wrap align-center justify-center class="opansans-font ma-0">
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center>
        <v-img :src="loginImg" alt="loginLogo" class="mx-auto" max-width="60%"></v-img>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center>
        <v-row class="ma-0 justify-center">
          <v-card class="pa-6 cardBoxShadow rounded-lg" max-width="380" >
            <!-- <form @keyup.enter="login()">
              <span class="fsize24 themeBlue"> {{ getBrokerName }}</span>
              <div class="fsize22 mb-6 primaryColor">
                {{ getBrokerName }} Admin Dashboard
              </div>
              <div>
                <p class="mb-2 ml-1 fsize14 primaryColor">Username</p>
                <v-text-field v-model.trim="userName" class="fsize11" placeholder="Enter the username"
                  :rules="userNameRules" outlined dense autocomplete></v-text-field>
              </div>
              <div>
                <p class="mb-2 ml-1 fsize14 primaryColor">Password</p>
                <v-text-field v-model="password" placeholder="Enter the password" :rules="passwordRules" class="fsize11"
                  outlined dense :type="passwordShow ? 'text' : 'password'"
                  :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordShow = !passwordShow"
                  autocomplete></v-text-field>
              </div>
              <v-btn :loading="getLoader" @click="login()" block depressed
                class="login-btn text-capitalize mt-3 primary" :disabled="!userName || !password">
                Sign In</v-btn>
            </form> -->
            <div class="fsize16 mb-2 text-center"><strong>Developer Console</strong></div>
            <div class="mb-4 d-flex justify-center ">  <img src="../assets/images/antlogo.svg" width="50px" alt=""/> </div>
            <div class="d-flex align-start fsize14">
              <img src="../assets/images/warning_outline.svg" class="mr-2" alt=""> You have successfully logged out. If you wish to continue with login, please click below
            </div>
            <div v-if="error_message">{{error_message}}</div>
             <v-btn id="continue_with_web_btn" block depressed @click="login();error_message= null"
                class="login-btn text-capitalize mt-3 primary" >
                Continue with Web</v-btn>
          </v-card>
        </v-row>
      </v-flex>
    </v-layout>

    <div v-if="isParamAvil" class="d-flex align-center justify-center" style="height:100vh !important">
      <img height="60" width="60" src="../assets/Spinner.gif" alt="loading">
    </div>

  </v-app>
</template>
<script>
import loginImg from "../assets/loginPic.png";
import { mapGetters } from "vuex";
export default {
  name: "Login",
  data: () => ({
    loginImg: loginImg,
    userName: "",
    password: "",
    passwordShow: false,
    userNameRules: [
      (v) => !!v || "Username is required",
      // v => (v && v.length <= 20) || 'Username must be less than 20 characters'
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be at least 6 characters",
    ],
    isParamAvil:false
  }),
  computed: {
    ...mapGetters(["getBrokerName"]),
    error_message : {
      get(){
        return this.$store.state.auth.error_message
      },
      set(val){
        this.$store.commit('auth/setErrorMessage', val)
      }
    }
  },
  methods: {
    login() {
      window.location.href = "https://ant.aliceblueonline.com?appcode=G9EOSWCEIF9ARCB";
    },
  },
  created() {
    
    
    if(this.$store.state.sessionId && this.$store.state.userId){
       this.$router.push('apps').catch(()=>{})
    }else{
      let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    if (params.get("authCode")) {
      this.isParamAvil = true
      this.$store.dispatch("auth/getData", params.get("authCode"));
    } else if(params.get("logout")){
      
    }else {
      this.login();
    }
    }
  },
};
</script>